.top {
  width: 100%;
  height: 50px;
  background-color: white;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  font-family: "Josefin Sans", sans-serif;
  z-index: 999;
}

.topLeft,
.topRight {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topCenter {
  flex: 6;
}

.topIcon {
  font-size: 20px;
  margin-right: 10px;
  color: #4444;
  cursor: pointer;
}
.topImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.topList {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
.topListItem {
  margin-right: 20px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}
