.login {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  );
  background-size: cover;
}

.loginTitle {
  font-size: 50px;
}

.loginForm {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.loginForm > label {
  margin: 10px 0;
}

.loginInput {
  padding: 10px;
  background-color: white;
  border: none;
}

.loginButton {
  margin-top: 20px;
  cursor: pointer;
  background-color: lightcoral;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 10px;
}

.loginButton:disabled {
  cursor: not-allowed;
  background-color: rgb(252, 173, 173);
}

.loginRegisterButton {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: teal;
  cursor: pointer;
  border: none;
  padding: 10px;
  color: white;
  border-radius: 10px;
}
