.singlePost {
  flex: 9;
}
.singlePostWrapper {
  padding: 20px;
  padding-right: 0;
}
.singlePostImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border-radius: 5px;
  object-fit: cover;
}
.singlePostTitle {
  text-align: center;
  margin: 10px;
  font-family: "Lora", serif;
  font-size: 28px;
}

.singlePostEdit {
  float: right;
  font-size: 16px;
}
.singlePostIcon {
  margin-right: 10px;
  cursor: pointer;
}
.singlePostIcon:first-child {
  color: teal;
}
.singlePostIcon:last-child {
  color: tomato;
}
.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #b39656;
  font-family: "Varela", sans-serif;
}
.singlePostDesc {
  color: #666;
  font-size: 18px;
  line-height: 25px;
}
.singlePostDesc::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}
